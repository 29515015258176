// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/login.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/login.tsx");
  import.meta.hot.lastModified = "1721559398186.917";
}
// REMIX HMR END

import { json, redirect } from "@remix-run/node";
import AdminController from "~/controllers/AdminController.server";
import { validateEmail } from "~/validators.server";
import { Form, Button, Input } from "antd";
import { useSubmit } from "@remix-run/react";
export default function Login() {
  _s();
  const [loginForm] = Form.useForm();
  const {
    Item
  } = Form;
  const submit = useSubmit();
  return <div className="flex flex-col md:flex-row h-screen">
      <div className="w-1/2"></div>

      <div className="flex-1 flex items-center justify-center flex-col gap-3">
        <h2 className="font-sen font-bold text-4xl text-slate-800 text-center">
          Sign in to Your Account
        </h2>

        <Form onFinish={() => {
        loginForm.validateFields().then(values => {
          submit(values, {
            method: "POST"
          });
        }).catch(info => {
          console.log("Validate Failed:", info);
        });
      }} requiredMark={false} layout="vertical" name="login" form={loginForm}>
          <Item label="Email" name={"email"} hasFeedback rules={[{
          required: true
        }, {
          type: "email"
        }]}>
            <Input type="email" />
          </Item>

          <Item label="Password" name={"password"} hasFeedback rules={[{
          required: true
        }]}>
            <Input type="password" />
          </Item>

          <Item>
            <Button type="primary" htmlType="submit" className="bg-blue-600">
              Submit
            </Button>
          </Item>
        </Form>
      </div>
    </div>;
}
_s(Login, "iTT5wJ7hP27Zi6fsz0j56RWXvJc=", false, function () {
  return [Form.useForm, useSubmit];
});
_c = Login;
export const action = async ({
  request
}) => {
  const formData = await request.formData();
  const email = formData.get("email");
  const password = formData.get("password");
  const errors = {
    email: !validateEmail(email) && "Invalid email"
    // password: !validatePassword(password) && "Invalid password",
  };
  if (Object.values(errors).some(Boolean)) {
    return json({
      errors
    }, {
      status: 400
    });
  }
  const adminController = await new AdminController(request);
  return await adminController.loginAdmin({
    email,
    password
  });
};
export const loader = async ({
  request
}) => {
  const adminController = await new AdminController(request);
  return (await adminController.getAdmin()) ? redirect("/") : null;
};
var _c;
$RefreshReg$(_c, "Login");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;